// Importing necessary React components, styles, and images
import { Grid, FormControl, InputLabel, Input } from '@mui/material';
import { Button } from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import SignInImage from '../../../images/SignIn.png';
import Close from '../../../images/close.svg';

import { loginUser } from '../../../api';
import { useUser } from '../../../UserContext';
import Cookies from 'js-cookie';

/**
 * Login Component
 * 
 * @param {Object} props - React component props.
 * @param {Function} props.handleClose - Function to handle closing the Login modal.
 * @param {Function} props.handleRegisterOpen - Function to handle opening the Register modal.
 * @param {Function} props.handleForgot - Function to handle opening the Forgot Password modal.
 * @returns {ReactNode} - Rendered component for the Parent Sign-in feature.
 */
export const Login = ({ handleClose, handleRegisterOpen, handleForgot }) => {
  // State to manage user details and login-related state
  const [userDetails, setUserDetails] = useState({});
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Accessing user-related functions from UserContext
  const { setUserData } = useUser();

  // Accessing navigation function from react-router-dom
  const history = useNavigate();

  // Function to handle form submission
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      // Attempting to log in the user
      const res = await loginUser(userDetails);

      // Storing user information in local storage, session storage, and cookies
      localStorage.setItem('latestUserId', res.data.userInfo.userid);
      sessionStorage.setItem('currentUserId', res.data.userInfo.userid);
      Cookies.set('userId', res.data.userInfo.userid, { expires: 7 }); // Expires in 7 days

      // Setting user data in UserContext
      setUserData({
        ...res.data,
        isAuthenticated: true,
        userInfo: { ...res.data.userInfo },
      });

      // Removing sensitive information before storing in local storage
      delete res.data.userInfo.password;

      // Creating a user object for storage
      const userObject = {
        isAuthenticated: true,
        userInfo: { ...res.data.userInfo },
      };

      // Storing user object in local storage
      localStorage.setItem('user', JSON.stringify(userObject));

      // Navigating to the profile page
      history('/parent/profile');
    } catch (err) {
      // Handling login error
      setLoginError(true);
      setErrorMessage('Invalid email id or password!');
    }
  };

  return (
    <div className="register">
      {/* Image section */}
      <img src={SignInImage} alt="" />

      {/* Form section */}
      <div className="">
        <div className="forms-heading">Parent Sign in</div>
        <div className="forms-subheading">
          Forgot Password New to HiSanta?{' '}
          {/* Link to open the Register modal */}
          <span
            className="forgot-password-link"
            onClick={() => {
              handleClose(false);
              handleRegisterOpen(true);
            }}
          >
            Register here
          </span>{' '}
          for free
        </div>

        {/* Display login error message */}
        <div className='error-message'>
          {loginError && <p>{errorMessage}</p>}
        </div>

        {/* Forms content */}
        <div className="forms-content">
          {/* Close button */}
          <div className="close-btn" onClick={() => handleClose(false)}>
            <img src={Close} alt="close" />
          </div>

          {/* Login form */}
          <form onSubmit={handleSubmit}>
            <Grid container spacing="0">
              {/* Email input field */}
              <Grid item xs="12">
                <FormControl variant="standard" sx={{ width: '100%', margin: '1rem' }} required>
                  <InputLabel id="email">Enter Email</InputLabel>
                  <Input
                    labelId="email"
                    id="email"
                    variant="standard"
                    onChange={e => {
                      setUserDetails({ ...userDetails, emailId: e.target.value });
                      setErrorMessage('');
                    }}
                  />
                </FormControl>
              </Grid>

              {/* Password input field */}
              <Grid item xs="12">
                <FormControl variant="standard" sx={{ width: '100%', margin: '1rem' }} required>
                  <InputLabel id="password">Enter Password</InputLabel>
                  <Input
                    type="password"
                    labelId="password"
                    id="password"
                    variant="standard"
                    onChange={e => {
                      setUserDetails({ ...userDetails, password: e.target.value });
                      setErrorMessage('');
                    }}
                  />
                </FormControl>
              </Grid>

              {/* Submit button */}
              <Button type="submit" variant="fullWidth" style={{ marginTop: '8px' }}>
                Submit
              </Button>

              {/* Forgot Password link */}
              <div
                className="forgot-password-link"
                onClick={() => {
                  handleClose(false);
                  handleForgot(true);
                }}
                style={{ margin: 'auto', marginTop: '1rem' }}
              >
                Forgot Password?
              </div>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};

import { useState, useEffect, useContext } from 'react';

import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import './index.css';
import { KidProfile } from './components/KidProfile/KidProfile';
import { getChildInfo } from '../../../../api';
import { useUser } from '../../../../UserContext';
import LoaderContext from '../../../../context/LoaderContext';
import AddKidsFirst from '../../../../images/AddKidsFirst.png';

export const Wishlist = () => {
  // const [productList, seProductList] = useState([]);
  const [childInfo, setChildInfo] = useState([]);
  const [value, setValue] = useState(1);
  const { userData } = useUser();
  // eslint-disable-next-line no-undef
  const { setIsLoading, setMessage } = useContext(LoaderContext);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setIsLoading(true);
    setIsDataLoading(true);
    setMessage('Fetching child wishlist data, please wait.');
    getChildInfo({ userId: userData.userInfo.userid }).then(res => {
      setIsLoading(false);
      setIsDataLoading(false);
      setChildInfo(res.data.childInfo);
    });
    return () => {
      setIsLoading(false);
      setMessage();
    };
  }, []);

  return (
    <div>
      {!isDataLoading && childInfo && !childInfo.length ? (
        <div className="fetch-data" style={{ marginTop: 0 }}>
          <img src={AddKidsFirst} alt="No Child Profiles Found" style={{ width: '40%', height: 'auto' }} />
        </div>
      ) : (
        ''
      )}
      {!isDataLoading && childInfo && childInfo.length > 0 && (
        <div className="wishlist">
          <div className="page-heading">Registry</div>
          <div className="content">
            <div className="tabs">
              <TabContext value={value}>
                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="Tabs" >
                      {childInfo.map((child, i) => {
                        return <Tab label={child.childname} value={i + 1} key={i} />;
                      })}
                    </TabList>
                  </Box> */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="Tabs"
                    variant="scrollable" // Enable scrolling
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                  >
                    {childInfo.map((child, i) => {
                      return <Tab label={child.childname} value={i + 1} key={i} />;
                    })}
                  </TabList>
                </Box>
                <div>
                  {childInfo.map((child, i) => {
                    return (
                      <TabPanel value={i + 1} key={i}>
                        <KidProfile child={child} />
                      </TabPanel>
                    );
                  })}
                </div>
              </TabContext>
            </div>
            <br />
          </div>
        </div>
      )}
    </div>
  );
};

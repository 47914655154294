// GiftGiver.js
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppHeader } from '../../components/AppHeader';
import { Button } from '../../components/Button';
import { LetterPreview } from '../../components/LetterPreview/LetterPreview';
import { RegistryList } from './RegistryList';
import { getWishlist } from '../../api';
import LoaderContext from '../../context/LoaderContext';
import './index.css';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

/**
 * GiftGiver Component
 *
 * @returns {JSX.Element} - Returns the JSX element for the GiftGiver component.
 */
export const GiftGiver = () => {
  const [wishList, setWishlist] = useState([]);
  const [letterContent, setLetterContent] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const { setIsLoading, setMessage } = useContext(LoaderContext);
  const [loading, setLoading] = useState(false);

  const query = useQuery();
  const userid = query.get('userid');
  const childid = query.get('childid');

  useEffect(() => {
    setIsLoading(true);
    setLoading(true);
    setMessage('Fetching wishlist...');
    if (userid) {
      getWishlist({ userid }).then(res => {
        setIsLoading(false);
        setLoading(false);
        setWishlist(res.data.wishList);
      });
    }
  }, [userid, childid, setIsLoading, setMessage]);

  useEffect(() => {
    const listItem = wishList.find(list => list.childid === childid);
    if (wishList.length && listItem) {
      setLetterContent({
        name: listItem.lettercontent.name,
        age: listItem.lettercontent.age,
        behaviour: listItem.lettercontent.haveBeen,
        niceThings: listItem.lettercontent.did,
        thingsNextYear: listItem.lettercontent.willDo,
        gratefulFor: listItem.lettercontent.greatful,
        wishes: listItem.lettercontent.wishlist,
        position: listItem.lettercontent.position,
        templateUrl: listItem.lettertemplateurl,
      });
      setActiveStep(listItem.lettertemplateurl ? 0 : 1);
    }
  }, [wishList, childid]);

  return (
    <>
      {activeStep === 0 && (
        <div>
          <AppHeader showParentSignIn={true} />
          {!loading && userid && childid && wishList.length > 0 && (
            <div>
              <div className="giftgiver">
                <div className="heading">{letterContent.name}&apos;s Letter</div>
                <div className="subtext">
                  Here is {letterContent.name}&apos;s letter to Santa Claus, in which he/she shares his thoughts and
                  wishes.
                </div>
                <div
                  className="content"
                  style={{
                    backgroundImage: `url(${letterContent.templateUrl})`,
                  }}
                >
                  <LetterPreview letterContent={letterContent} />
                </div>
                <div className="cta">
                  <Button onClick={() => setActiveStep(1)}>Next</Button>
                </div>
              </div>
            </div>
          )}
          {!loading && (!userid || !childid || !wishList || !wishList.length) && (
            <div className="message">Requested order is not available!</div>
          )}
        </div>
      )}
      {activeStep === 1 && (
        <RegistryList setActiveStep={setActiveStep} isBackButtonVisible={!!letterContent.templateUrl} />
      )}
    </>
  );
};

import './index.css';
import { Button } from '../../../../components/Button';
import Footer from '../../../../components/Footer';
import { AppHeader } from '../../../../components/AppHeader';

export const Tnc = ({ homePage }) => {
  return (
    <>
      {homePage && <AppHeader showParentSignIn />}
      <div className="tnc">
        <h2 style={{ color: 'red', textAlign: 'center' }}>­ ­Terms & Conditions </h2>
        <h4 style={{ marginBottom: '0.5rem' }}>©2024 ADcademics, LLC. All Rights Reserved.</h4>
        <h4>Privacy Policy for www.jollywishlist (also referred to in this Privacy Policy as “this site”)</h4>
        This site is owned by ADcademics LLC (dba “ADcademics”) (referred to collectively in this Privacy Policy as
        “ADcademics”). Please direct any questions or comments concerning the Privacy Policy below to
        info@adcademics.com.
        <h4>Please read our privacy policy carefully before using this site.</h4>
        Your privacy on the internet is of the utmost importance to us. Because we gather certain types of information
        about the users of this site, we feel you should fully understand the terms and conditions surrounding the
        capture and use of that information. This privacy policy discloses the privacy practices for this site (“this
        site”) – what information we gather, how we use it and how to correct or change it. This privacy policy only
        addresses our activities from our servers. Other sites (including those to which this site links and third party
        sites or services with which ADcademics may have relationships) may have their own privacy policies and
        practices, which we do not control, and we are not responsible for nor do we endorse such practices or policies.
        <h4>General Privacy Information</h4>
        This site contains links to partners, advertisers and third party internet sites and services who may collect
        data in connection with sweepstakes, promotions, e-commerce, etc. They each have separate privacy and data
        collection practices, independent of this site and ADcademics has no responsibility or liability for these
        independent policies or actions and is not responsible for the privacy practices or the content of such sites.
        Please keep in mind that whenever you voluntarily and publicly disclose personal information on any online
        service or website – for example through email – that information can be collected and used by others. In short,
        if you post personal information online that is accessible to the public, you may receive unsolicited messages
        from other parties in return. Further, while we take reasonable steps to protect your personal information using
        the security and other measures set forth herein, ADcademics cannot ensure or warrant the security of any
        information you transmit to us, and you do so at your own risk. You are solely responsible for maintaining the
        secrecy of your passwords and/or any account information. Please be careful and responsible whenever you are
        online.
        <h4>Collection and Use of Information</h4>
        This site collects information about you both from what you tell us and from what we collect through the use of
        cookies (see Cookies below). Information collected about you is used in two different ways. Information is
        “aggregated” — meaning that the information from many users is grouped together in a way that does not disclose
        the personal information of any particular user. We may use this aggregated information to determine user
        traffic statistics for this site (including without limitation to determine browser usage, duration of stay, the
        referring URL, IP address geographical location, etc.) and to evaluate which products and services are
        successful and which ones are not, and in order to evaluate which new services we should make available on this
        site. We may use only the anonymous click stream data to help our advertisers deliver better-targeted
        advertisements. In addition, ADcademics may use personal information collected through cookies and registration
        pages to target e-commerce promotions and telemarketing activities for products and services related to
        ADcademics and/or its sponsors, advertisers and business partners. In addition, some of your personal
        information may be shared with third parties who assist ADcademics in producing its site or provide a service to
        this site’s users. It may be necessary ? by law, legal process, litigation, and/or requests from public and
        governmental authorities within or outside your country of residence ? for ADcademics to disclose your personal
        information. ADcademics may also disclose information about you if it is determined that for purposes of
        national security, law enforcement, or other issues of public importance, disclosure is necessary or
        appropriate. Additionally, in the event of a reorganization, merger, or sale ADcademics may transfer any and all
        personal information collected to the relevant third party.
        <h4>Opt Out</h4>
        Users may always opt-out of receiving any information either by responding to the relevant communication as
        directed or by contacting this site. Financial information that is collected may be used to bill the user for
        any products and services purchased from ADcademics. The registrant’s contact information is also used to
        contact the user who has purchased good from this site when necessary for customer service reasons. Unique
        identifiers, such as ID and passwords, are collected to verify the user’s identity and for use as account
        numbers in our record system.
        <h4>Children Under 13</h4>
        This website is a general audience website. On this website, we do not currently knowingly collect any personal
        information from children under the age of 13. If, in the future, we collect personally identifiable information
        from children on the Website, we will do so in compliance with the Children’s Online Privacy Protection Act of
        1998 (15 U.S.C. 6501 et seq.) including, without limitation, obtaining necessary parental consent. If we learn
        that we have collected the personal information of a child under the age of 13, we will take steps to delete the
        information as soon as possible.
        <h4>Cookies</h4>A cookie is a small data file that certain websites write to your hard drive when you visit
        them. A cookie file can contain information such as a user ID that the site uses to track the pages you’ve
        visited. But the only personal information a cookie can contain is information you supply yourself. Generally,
        cookies work by assigning a unique number to the user that has no meaning outside the assigning site. A cookie
        can’t read data off your hard disk or read cookie files created by other sites. This random number lets us keep
        track of “how many” times customers are doing specific things – like visiting our site each month – without
        really knowing who those customers are. We use cookies to deliver content specific to your interests and to save
        your member ID. Additionally, after you’ve entered your member ID and password during a session on our service,
        we save that information so you don’t have to reenter it repeatedly during that session. Users should be aware
        that this site cannot control the use of cookies or the resulting information placed by advertisers or third
        parties hosting data for this site. If a user does not want information collected through the use of cookies,
        there is a simple procedure in most browsers that allows the user to deny or accept the cookie feature; however,
        users should note that cookies maybe necessary to provide the user with certain features (e.g., customized
        delivery of information) available on this site.
        <h4>Newsletter</h4>
        We may offer newsletters to share content information about this site with interested users. We use contact data
        from our newsletter subscriptions to send users the newsletter. On occasion we will email customers promotional
        material from some of our sponsors, advertisers or business partners. Users may cancel their free email
        subscriptions and other communications by following the simple instructions at the bottom of each newsletter or
        email, or by contacting this site.
        <h4>Security</h4>
        This site utilizes a variety of security measures to maintain the safety of your personal information. All user
        contact information is contained behind a “firewall” and only accessible by a limited number of employees who
        have special access rights to production systems. Passwords and credit card numbers are encrypted in this site’s
        secure database, which is also located behind a firewall. All sensitive/credit information supplied by users is
        transmitted via Secure Socket Layer (SSL) technology and then encrypted in this site’s databases as stated
        above. ADcademics’s security policies are reviewed periodically and revised as required.
        <h3>ACCESSIBILITY STATEMENT</h3>
        <h4>General</h4>
        jollywishlist strives to ensure that its services are accessible to people with disabilities.
        jollywishlist has invested a significant amount of resources to help ensure that its website is made easier
        to use and more accessible for people with disabilities, with the strong belief that every person has the right
        to live with dignity, equality, comfort and independence.
        <h4>Accessibility on jollywishlist</h4>
        jollywishlist makes available the UserWay Website Accessibility Widget that is powered by a dedicated
        accessibility server. The software allows jaydacey.com to improve its compliance with the Web Content
        Accessibility Guidelines (WCAG 2.1). On this site you may find documents in Portable Document Format (PDF), Word
        (.doc), Excel (.xls) Macro Enabled Excel (.xlms) and Powerpoint (ppt). If you have trouble accessing any of
        these files, visit the Microsoft Office website and the Adobe website for instructions on how to access
        Microsoft Office files and Portable Document Format files.
        <h4>Enabling the Accessibility Menu</h4>
        The jollywishlist accessibility menu can be enabled by clicking the accessibility menu icon that appears on
        the corner of the page. After triggering the accessibility menu, please wait a moment for the accessibility menu
        to load in its entirety.
        <h4>Disclaimer</h4>
        jollywishlist continues its efforts to constantly improve the accessibility of its site and services in the
        belief that it is our collective moral obligation to allow seamless, accessible and unhindered use also for
        those of us with disabilities. Despite our efforts to make all pages and content on jollywishlist fully
        accessible, some content may not have yet been fully adapted to the strictest accessibility standards. This may
        be a result of not having found or identified the most appropriate technological solution.
        <h4>Here for You</h4>
        If you are experiencing difficulty with any content on jaydacey.com or require assistance with any part of our
        site, please contact us during normal business hours as detailed below and we will be happy to assist.
        <h4>Contact Us</h4>
        If you wish to report an accessibility issue, have any questions or need assistance, please contact
        jollywishlist Customer Support as follows: info@jollywishlist
        {homePage && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '4rem' }}>
            <Button onClick={() => window.history.go(-1)}>Back</Button>
          </div>
        )}
      </div>
      {homePage && <Footer />}
    </>
  );
};

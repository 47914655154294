import React from 'react';
import './style.css';

export const StarOne = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 100 100" className="svg-one">
      <g className="group">
        <g className="large">
          <path
            d="M41.25,40 L42.5,10 L43.75,40 L45,41.25 L75,42.5 L45,43.75 L43.75,45 L42.5,75 L41.25,45 L40,43.75 L10,42.5 L40,41.25z"
            fill="white"
            // stroke="white"
            // strokeWidth="1.5"
          />
        </g>
        <g className="large-2" transform="rotate(45)">
          <path
            d="M41.25,40 L42.5,10 L43.75,40 L45,41.25 L75,42.5 L45,43.75 L43.75,45 L42.5,75 L41.25,45 L40,43.75 L10,42.5 L40,41.25z"
            fill="white"
          />
        </g>
        <g className="small">
          <path
            d="M41.25,40 L42.5,25 L43.75,40 L45,41.25 L60,42.5 L45,43.75 L43.75,45 L42.5,60 L41.25,45 L40,43.75 L25,42.5 L40,41.25z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export const StarTwo = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 100 100" className="svg-two">
      <g className="group">
        <g className="large">
          <path
            d="M41.25,40 L42.5,10 L43.75,40 L45,41.25 L75,42.5 L45,43.75 L43.75,45 L42.5,75 L41.25,45 L40,43.75 L10,42.5 L40,41.25z"
            fill="white"
          />
        </g>
        <g className="large-2" transform="rotate(45)">
          <path
            d="M41.25,40 L42.5,10 L43.75,40 L45,41.25 L75,42.5 L45,43.75 L43.75,45 L42.5,75 L41.25,45 L40,43.75 L10,42.5 L40,41.25z"
            fill="white"
          />
        </g>
        <g className="small">
          <path
            d="M41.25,40 L42.5,25 L43.75,40 L45,41.25 L60,42.5 L45,43.75 L43.75,45 L42.5,60 L41.25,45 L40,43.75 L25,42.5 L40,41.25z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export const StarThree = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 100 100" className="svg-three">
      <g className="group">
        <g className="large">
          <path
            d="M41.25,40 L42.5,10 L43.75,40 L45,41.25 L75,42.5 L45,43.75 L43.75,45 L42.5,75 L41.25,45 L40,43.75 L10,42.5 L40,41.25z"
            fill="white"
          />
        </g>
        <g className="large-2" transform="rotate(45)">
          <path
            d="M41.25,40 L42.5,10 L43.75,40 L45,41.25 L75,42.5 L45,43.75 L43.75,45 L42.5,75 L41.25,45 L40,43.75 L10,42.5 L40,41.25z"
            fill="white"
          />
        </g>
        <g className="small">
          <path
            d="M41.25,40 L42.5,25 L43.75,40 L45,41.25 L60,42.5 L45,43.75 L43.75,45 L42.5,60 L41.25,45 L40,43.75 L25,42.5 L40,41.25z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

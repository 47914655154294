import Slider from 'react-slick';
import { CarouselContainer } from './CarouselContainer';
import './ProductCarousel.css';
import { useMediaQuery } from '@mui/material';

const chunkArray = (arr, chunkSize) => {
  const chunkedArray = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunkedArray.push(arr.slice(i, i + chunkSize));
  }
  return chunkedArray;
};

export const ProductCarousel = ({ index, name, products, hadndleSelectProduct }) => {
  const smallScreen = useMediaQuery('(max-width:1200px)');
  const mediumScreen  = useMediaQuery('(max-width:1600px)');
  const chunkSize = smallScreen ? 1 : (mediumScreen ? 2 : 3);

  const chunkedArray = chunkArray(products, chunkSize);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="product-carousel">
      <div className="title">
        Product {index}:<span>{name}</span>
      </div>
      <div className="carousel">
        <Slider {...settings}>
          {chunkedArray &&
            chunkedArray.map((chunk, index) => {
              return <CarouselContainer key={index} products={chunk} hadndleSelectProduct={hadndleSelectProduct} />;
            })}
        </Slider>
      </div>
    </div>
  );
};

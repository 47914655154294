import { NavLink, useLocation } from 'react-router-dom';
import { Logo } from '../../../../components/Logo';
import Profile from '../../../../images/profile.svg';
import Template from '../../../../images/template-gallery.svg';
import Kids from '../../../../images/kids.svg';
import Wishlist from '../../../../images/wishlist.svg';
import Faq from '../../../../images/faq.svg';
import Tnc from '../../../../images/tnc.svg';
import Privacy from '../../../../images/privacy.svg';
import LogOut from '../../../../images/logout.svg';
import './index.css';
import { useUser } from '../../../../UserContext';
import Cookies from 'js-cookie';

export const Sidebar = ({ toggleSidebar }) => {
  const { pathname } = useLocation();
  const { setUserData } = useUser();

  const logOutHandler = () => {
    setUserData({});
    localStorage.removeItem('latestUserId');
    localStorage.removeItem('user');
    Cookies.remove('userId');
  };
  return (
    <div className="sidebar">
      <div className="top">
        <Logo style={{ height: '6rem', padding: '2rem' }} />
        <NavLink to="profile" className={pathname === 'profile' ? 'active' : ''}>
          <div className="navbar" onClick={toggleSidebar}>
            <img src={Profile} alt="" />
            Profile
          </div>
        </NavLink>
        <NavLink to="kid" className={pathname === 'templates' ? 'active' : ''}>
          <div className="navbar" onClick={toggleSidebar}>
            <img src={Kids} alt="" />
            Children
          </div>
        </NavLink>
        {/* <NavLink to="templates"  className={pathname === 'templates' ? 'active': ''}>
                    <div className='navbar'>
                        <img src={Template} alt="" />
                        Templates
                    </div>
                </NavLink> */}
        <NavLink to="wishlist" className={pathname === 'wishlist' ? 'active' : ''}>
          <div className="navbar" onClick={toggleSidebar}>
            <img src={Wishlist} alt="" />
            Wishlist
          </div>
        </NavLink>
      </div>
      <div className="bottom">
        <NavLink to="faqs" className={pathname === 'faqs' ? 'active' : ''}>
          <div className="navbar" onClick={toggleSidebar}>
            <img src={Faq} alt="" />
            FAQs
          </div>
        </NavLink>
        <NavLink to="terms-and-conditions" className={pathname === 'terms-and-conditions' ? 'active' : ''}>
          <div className="navbar" onClick={toggleSidebar}>
            <img src={Tnc} alt="" />
            Terms & Conditions
          </div>
        </NavLink>
        <NavLink to="privacy-policy" className={pathname === 'privacy-policy' ? 'active' : ''}>
          <div className="navbar" onClick={toggleSidebar}>
            <img src={Privacy} alt="" />
            Privacy Policy
          </div>
        </NavLink>
        <div className="navbar" onClick={() => logOutHandler()}>
          <img src={LogOut} alt="" />
          Logout
        </div>
      </div>
    </div>
  );
};

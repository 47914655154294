import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button } from '../../../../components/Button';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

// Import necessary components from Material-UI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { updateProfile, getProfile } from '../../../../api';
import KidsForm from './KidsForm';
import AccordionComponent from '../../../../components/AccordionComponent';

import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
  Snackbar,
  Alert,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import './index.css';
import EditIcon from '@mui/icons-material/Edit';

import { useUser } from '../../../../UserContext';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import LoaderContext from '../../../../context/LoaderContext';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export function Profile() {
  const { userData } = useUser();
  const history = useNavigate();
  const { setIsLoading, setMessage } = useContext(LoaderContext);
  const formRef = useRef(null);

  // State Hooks
  const [isProfileEditing, setIsProfileEditing] = useState(false);
  const [isChildEditing, setIsChildEditing] = useState(false);
  const [editingChild, setEditingChild] = useState({});
  const [isPasswordEditing, setIsPasswordEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [kidsDetails, setKidsDetails] = useState([]);
  const [childDeleted, setChildDeleted] = useState([]);
  const [parentProfileDetails, setParentProfileDetails] = useState({ userInfo: {}, childInfo: [] });
  const [showInfoBox, setShowInfoBox] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newChildDetails, setNewChildDetails] = useState({
    name: '',
    gender: '',
    age: '',
    shoeSize: '',
    topSize: '',
    bottomSize: '',
  });

  const initializeUserDetails = (userInfo, userId) => ({
    firstName: userInfo.firstname,
    lastName: userInfo.lastname,
    emailId: userInfo.emailid,
    password: userInfo.password || '',
    cnfPassword: userInfo.password || '',
    userId,
  });

  const initializeChildDetails = childInfo =>
    childInfo.map(child => ({
      childId: child.childid,
      gender: child.gender,
      name: child.childname,
      age: child.childage,
      topSize: child.topsize,
      bottomSize: child.bottomsize,
      shoeSize: child.shoesize,
    }));

  // State hooks
  const [userDetails, setUserDetails] = useState(() =>
    initializeUserDetails(parentProfileDetails.userInfo, userData.userInfo.userid)
  );

  const [childDetails, setChildDetails] = useState(() => initializeChildDetails(parentProfileDetails.childInfo));

  const [expanded, setExpanded] = useState('add-kid');

  // Effect Hooks
  useEffect(() => {
    setIsLoading(true);
    setMessage('Fetching profile...');
    getProfile({ userid: userData.userInfo.userid }).then(res => {
      setIsLoading(false);
      setParentProfileDetails({ userInfo: res.data.userInfo, childInfo: res.data.childInfo });
    });
  }, []);

  useEffect(() => {
    setUserDetails(initializeUserDetails(parentProfileDetails.userInfo, userData.userInfo.userid));
    setChildDetails(initializeChildDetails(parentProfileDetails.childInfo));
  }, [parentProfileDetails, userData.userInfo.userid]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Helper Functions
  const handleClickOutside = event => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setIsProfileEditing(false);
      setIsPasswordEditing(false);
      setIsChildEditing(false);
    }
  };

  const handleInfoBoxClose = () => setShowInfoBox(false);
  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleMouseDownPassword = event => event.preventDefault();

  const handleProfileEdit = () => setIsProfileEditing(true);
  const handlePasswordEdit = () => setIsPasswordEditing(true);

  const handleChildEdit = id => {
    const child = childDetails.find(child => child.childId === id);
    setEditingChild(child);
    setIsChildEditing(true);
  };

  const handleChildDelete = id => {
    setChildDetails(prevChildDetails =>
      prevChildDetails.filter(child => {
        if (child.childId === id) {
          setChildDeleted(prev => [...prev, child.childId]);
          setAlertMessage(`${child.name} removed successfully`);
          setOpen(true);
        }
        return child.childId !== id;
      })
    );
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setUserDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleChildInputChange = e => {
    const { name, value } = e.target;
    const updatedChildInfo = childDetails.map(child => {
      if (child.childId === editingChild.childId) {
        setEditingChild(prev => ({ ...prev, [name]: value }));
        return { ...child, [name]: value };
      }
      return child;
    });
    setChildDetails(updatedChildInfo);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setNewChildDetails(prev => ({ ...prev, [name]: value }));
  };

  const addKidDetails = e => {
    e.preventDefault();
    if (newChildDetails.name.length !== 0) {
      setKidsDetails(prev => [...prev, newChildDetails]);
      setNewChildDetails({ name: '', gender: '', age: '', shoeSize: '', topSize: '', bottomSize: '' });
    }
  };

  const handleDelete = index => {
    setKidsDetails(prev => prev.filter((_, i) => i !== index));
  };

  const handleUpdateDetails = () => {
    const password = parentProfileDetails.userInfo.password === userDetails.password ? '' : userDetails.password;
    delete userDetails.emailId;

    const getChildDetailsPayload = kids => {
      console.log({ kids });
      return kids.map(kid => {
        return {
          childId: kid.childId,
          childname: kid.name,
          gender: kid.gender,
          childage: kid.age,
          topsize: kid.topSize,
          bottomsize: kid.bottomSize,
          shoesize: kid.shoeSize,
        };
      });
    };

    const payload = {
      userInfo: { ...userDetails, password },
      childInfo: getChildDetailsPayload(childDetails),
      childAdded: getChildDetailsPayload(kidsDetails),
      childDeleted,
    };

    if (userDetails.password !== userDetails.cnfPassword) {
      setAlertMessage('Password Not Matched');
      setOpen(true);
    } else {
      updateProfile(payload).then(() => {
        setAlertMessage('Profile Information Saved Successfully!');
        setKidsDetails([]);
        getProfile({ userid: userData.userInfo.userid }).then(res => {
          console.log('888888888 userInfo', res.data.userInfo);
          setParentProfileDetails({ userInfo: res.data.userInfo, childInfo: res.data.childInfo });
          handleOpenDialog();
          setIsChildEditing(false);
        });
      });
    }
  };

  const handleConfirmAction = () => {
    history('/kid');
    handleCloseDialog();
  };

  const handleClose = () => setOpen(false);

  // Component
  const TwoButtonDialog = ({ open, handleClose, handleConfirm, title, content, confirmLabel, cancelLabel }) => (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderPersonalDetails = () => {
    return (
      <div className="profile-form">
        {isProfileEditing ? (
          <div ref={formRef}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="firstName">First Name</InputLabel>
                  <Input
                    id="firstName"
                    name="firstName"
                    variant="standard"
                    value={userDetails.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="lastName">Last Name</InputLabel>
                  <Input
                    id="lastName"
                    name="lastName"
                    variant="standard"
                    value={userDetails.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <Input
                    id="email"
                    name="email"
                    variant="standard"
                    value={userDetails.emailId}
                    onChange={handleInputChange}
                    disabled
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '100vw' }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    First Name: <p className="value">{userDetails.firstName}</p>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    Last Name: <p className="value">{userDetails.lastName}</p>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    Email: <p className="value">{userDetails.emailId}</p>
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <EditIcon sx={{ cursor: 'pointer' }} onClick={handleProfileEdit} />
          </div>
        )}
      </div>
    );
  };

  const renderChangePassword = () => {
    return (
      <div className="profile-form" style={{ marginBottom: '2rem' }}>
        {isPasswordEditing ? (
          <div ref={formRef}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    variant="standard"
                    value={userDetails.password}
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="cnfPassword">Confirm Password</InputLabel>
                  <Input
                    id="cnfPassword"
                    name="cnfPassword"
                    type={showPassword ? 'text' : 'password'}
                    variant="standard"
                    value={userDetails.cnfPassword}
                    onChange={handleInputChange}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div style={{ width: '100vw' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    Password:{' '}
                    <p className="value" type="password">
                      {Array.from(userDetails.password).map((char, i) => {
                        return <span key={i}>*</span>;
                      })}
                    </p>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    Confirm Password:{' '}
                    <p className="value" type="password">
                      {Array.from(userDetails.cnfPassword).map((char, i) => {
                        return <span key={i}>*</span>;
                      })}
                    </p>
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <EditIcon sx={{ cursor: 'pointer' }} onClick={handlePasswordEdit} />
          </div>
        )}
      </div>
    );
  };

  const renderChildDetails = () => {
    return (
      <div className="profile-form" style={{ marginBottom: '2rem' }}>
        <div>
          {childDetails.map((child, index) => {
            return (
              <>
                {isChildEditing && child.childId === editingChild.childId ? (
                  <div>
                    <KidsForm newChildDetails={editingChild} handleChange={handleChildInputChange} />
                    <div className="page-section-separator" />
                  </div>
                ) : (
                  <div>
                    <div key={child.name} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ width: '100vw' }}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                              Name: <sapn className="value">{child.name}</sapn>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                              Gender: <sapn className="value">{child.gender}</sapn>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                              Age: <sapn className="value">{child.age}</sapn>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                              Shoe Size: <sapn className="value">{child.shoeSize}</sapn>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                              Top Size: <sapn className="value">{child.topSize}</sapn>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                              Botton Size: <sapn className="value">{child.bottomSize}</sapn>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                      {/* <Button variant="contained" color="primary" onClick={() => handleChildEdit(child.childname)}>
                Edit
              </Button> */}
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                        <EditIcon sx={{ cursor: 'pointer' }} onClick={() => handleChildEdit(child.childId)} />
                        <DeleteIcon sx={{ cursor: 'pointer' }} onClick={() => handleChildDelete(child.childId)} />
                      </div>
                    </div>
                    {index !== childDetails.length - 1 && <div className="page-section-separator" />}
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const renderAddKid = () => {
    return (
      <div>
        {/* <div className="page-section-separator" /> */}
        <div className="profile-form" style={{ marginBottom: '2rem' }}>
          <form onSubmit={addKidDetails}>
            <KidsForm newChildDetails={newChildDetails} handleChange={handleChange} />
            <Button type="submit" variant="contained" color="primary">
              Add Child
            </Button>
          </form>
        </div>

        {!!kidsDetails.length && (
          <TableContainer component={Paper} sx={{ marginBottom: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="right">Gender</StyledTableCell>
                  <StyledTableCell align="right">Age</StyledTableCell>
                  <StyledTableCell align="right">Shoe size</StyledTableCell>
                  <StyledTableCell align="right">Top Size</StyledTableCell>
                  <StyledTableCell align="right">Bottom Size</StyledTableCell>
                  <StyledTableCell align="right">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {kidsDetails.map((row, i) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.gender}</StyledTableCell>
                    <StyledTableCell align="right">{row.age}</StyledTableCell>
                    <StyledTableCell align="right">{row.shoeSize}</StyledTableCell>
                    <StyledTableCell align="right">{row.topSize}</StyledTableCell>
                    <StyledTableCell align="right">{row.bottomSize}</StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton color="primary" onClick={() => handleDelete(i)}>
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  };

  return (
    <div className="profile">
      <TwoButtonDialog
        open={isDialogOpen}
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmAction}
        title="Profile updated successfully!"
        content="STEP 2 : Select 'Launch Child Profile' if you want to compose a letter or talk to santa with configured profiles"
        confirmLabel="Launch Profile"
        cancelLabel="Cancel"
      />
      {showInfoBox && (
        <div
          className="info-box"
          style={{ position: 'relative', background: 'black', padding: '20px', fontSize: '18px', color: 'white' }}
        >
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleInfoBoxClose}
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              background: 'black',
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <span>
            <b>[STEP-1] PARENTS</b> <br /> - Enter your child’s profile information, so Santa can get the right
            age-appropriate toys or the right size of clothing for your child.
            <br />
            - Enter each child’s profile details and Press the “Add Child” button.
            <br />- When finished adding all of your children, click “Save Profile” to move on to the next step.
          </span>
          <br />
        </div>
      )}
      <br />

      <div className="page-heading">Profile Information</div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <AccordionComponent
          title="Personal Details"
          renderContent={renderPersonalDetails}
          onChange={() => setExpanded('personal-details')}
          isExpanded={expanded === 'personal-details'}
        />

        <AccordionComponent
          title="Change Password"
          renderContent={renderChangePassword}
          onChange={() => setExpanded('change-password')}
          isExpanded={expanded === 'change-password'}
        />

        <AccordionComponent
          title="Add Kid"
          renderContent={renderAddKid}
          onChange={() => setExpanded('add-kid')}
          isExpanded={expanded === 'add-kid'}
        />

        <AccordionComponent
          title="Child`s Details"
          renderContent={renderChildDetails}
          onChange={() => setExpanded('child-details')}
          isExpanded={expanded === 'child-details'}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateDetails}
            style={{ float: 'left'}}
          >
            Save Profile
          </Button>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

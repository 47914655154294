import Check from '../../../../../../images/check.png';
import Amazon from '../../../../../../images/amazon.png';
import Target from '../../../../../../images/target.png';
import Walmart from '../../../../../../images/walmart.png';

import { useState } from 'react';

import './ProductCard.css';
import StarRatings from 'react-star-ratings';
import Tooltip from '@mui/material/Tooltip';
import { Modal, Box } from '@mui/material';
import Close from '../../../../../../images/close.svg';
import ProductDetailsModal from '../../../../../../components/ProductDetailsModal';

const platformImages = {
  amazon: Amazon,
  walmart: Walmart,
  target: Target,
};

export const ProductCard = ({ product, variant, handleSelectProduct, shareList }) => {
  const [openViewDetails, setOpenViewDetails] = useState(false);

  const handleProductView = () => {
    setOpenViewDetails(true);
  };

  return (
    <div className="product-card">
      <div
        className="product-img"
        style={{ backgroundImage: `url(${product.productImage})`, backgroundSize: '7rem' }}
      />
      <div className="details">
        <Tooltip title={product.productDescription} arrow>
          <div className="description">
            {product.productDescription.slice(0, 60)}
            {'...'}
          </div>
        </Tooltip>
        <div className="details-price">
          <div className="price">{product.brandName}</div>
          <div className="platform">
            <StarRatings
              rating={product.productRating && +product.productRating}
              starRatedColor="#cc7222"
              numberOfStars={5}
              starDimension="15px"
              starSpacing="1px"
            />
          </div>
        </div>
        <div className="details-price">
          <div className="price">{product.productPrice}</div>
          {product.originalPrice !== '$undefined' && <div className="original-price">{product.originalPrice}</div>}
          <div className="platform">
            <img src={platformImages[product.seller]} alt="" />
          </div>
        </div>
        <div className="select-view-buttons">
          {variant && variant === 'registry' ? (
            <div className="unslected-product" onClick={() => window.open(product.productUrl)}>
              Order Now
            </div>
          ) : product.selected ? (
            <div className="selected-product">
              <span onClick={() => !shareList && handleSelectProduct(product)}>Selected</span>
            </div>
          ) : (
            <div className="unslected-product" onClick={() => handleSelectProduct(product)}>
              Select
            </div>
          )}
          <div className="unslected-product" onClick={() => handleProductView(product)}>
            View Details
          </div>
        </div>
      </div>
      <ProductDetailsModal
        openViewDetails={openViewDetails}
        setOpenViewDetails={setOpenViewDetails}
        product={product}
        shareList={shareList}
        handleSelectProduct={handleSelectProduct}
      />
    </div>
  );
};

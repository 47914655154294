import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import SantaTalkVideo from '../../../../images/chat-with-santa-video-new.mp4';
import './style.css';
// import AudioRecorder from './components/AudioRecorder';
import { Button } from '../../../../components/Button';
import { getProductList, saveWishList } from '../../../../api';
import { ProductCarousel } from '../../../parent/components/Wishlist/components/ProductCarousel/ProductCarousel';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useUser } from '../../../../UserContext';
import ProductDisplay from './ProductDisplay';
import ringtoneAudio from '../../../../images/ringtone.mp3';
import { useMediaQuery } from '@mui/material';

export default function SantaTalks({ setStep, selectedProfile }) {
  // Refs
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioContextRef = useRef(null);
  const processorNodeRef = useRef(null);
  const inputNodeRef = useRef(null);
  const streamRef = useRef(null);
  const silenceStartRef = useRef(null);
  const isSpeakingRef = useRef(false);
  const isRecordingRef = useRef(false);
  const audioRef = useRef(null);
  const isSantaSpeakingRef = useRef(true);
  const countRef = useRef(0);
  const { userData, userId } = useUser();
  const ringToneAudioRef = useRef(null);

  // State
  const [audioToTextData, setAudioToTextData] = useState(null);
  const [audioData, setAudioData] = useState(null);
  const [playVideo, setPlayVideo] = useState(false);
  const [sellerProductList, setSellerProductList] = useState([]);
  const [isRingtonePlaying, setIsRingtonePlaying] = useState(false);

  // Constants
  const SILENCE_THRESHOLD = 0.01; // Adjust based on environment
  const SILENCE_WAIT_TIME = 2500; // 1.5 seconds of silence

  // Derived values
  const isFetched = !!sellerProductList.length;
  const smallScreen = useMediaQuery('(max-width:480px)');

  const introText = [
    `Ho-Ho-Ho!!!!  Well hello ${selectedProfile.name}!  
        It is so great to see you.  I see that you have been a good ${selectedProfile.gender} this year!  
        Why don’t you tell me what is on your Wish List this year?  Let’s go one by one.  What’s the toy or game that you want THE MOST?!?!`,
    `Ho-Ho-Ho!!!  
  Hello there, ${selectedProfile.name}!  
  It’s wonderful to see you again. I’ve heard you’ve been an excellent ${selectedProfile.gender} this year!  
  Now, let’s talk about your Wish List. What’s that one special toy or game you’re dreaming of the most? Let’s hear it!`,
    `Ho-Ho-Ho!!!  
  Well, hello there, ${selectedProfile.name}!  
  It’s always a joy to see you. I see you’ve been an amazing ${selectedProfile.gender} this year!  
  Why don’t we dive into your Wish List? Tell me, what’s the number one toy or game on your mind?`,
  ];

  useEffect(() => {
    // Start monitoring when the component mounts and handle cleanup when unmounted
    startMonitoring();

    return () => {
      stopRecordingIfNeeded();
      pauseAudioIfPlaying();

      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (processorNodeRef.current) {
        processorNodeRef.current.disconnect();
      }
      if (inputNodeRef.current) {
        inputNodeRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    // Fetch and play initial greeting audio
    const randomNumber = Math.floor(Math.random() * 3);
    ringToneAudioRef.current.play();
    fetchAndPlayAudio(introText[randomNumber], false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (audioToTextData) {
        // Fetch and play audio based on recognized speech, and retrieve products
        // const prompt = `If input is related to wishlist in below statement please give some options or ask for wishlist \n ${audioToTextData}`;
        const products = await getProductsFromSpeech(audioToTextData);

        const productText = products.length > 1 ? `That` : `${products[0]}`;

        if (products && products.length) {
          await fetchAndPlayAudio(
            `${productText} is a great toy idea! My elves are working on a few of those! Do you want to select your favorite from this list?`,
            false
          );
        } else if (isFetched) {
          await fetchAndPlayAudio(
            `Hey ${selectedProfile.name}, got more wishes to share? I’d love to hear them! Let me know what you’re wishing for this magical eve , or feel free to wrap up the conversation!`,
            false
          );
        } else {
          await fetchAndPlayAudio(
            `Hey ${selectedProfile.name}, what’s on your mind? I’d love to know! Share your wish for this magical eve!`,
            false
          );
        }
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, [audioToTextData]);

  useEffect(() => {
    if (audioData) {
      playAudioAndHandleEnd();
    }
  }, [audioData]);

  useEffect(() => {
    handleVideoPlayback();
  }, [playVideo]);

  // Helper functions
  const stopRecordingIfNeeded = () => {
    if (mediaRecorderRef.current && isRecordingRef.current) {
      stopRecording();
    }
  };

  const pauseAudioIfPlaying = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const playAudioAndHandleEnd = () => {
    audioData.play();
    audioData.addEventListener('ended', () => {
      setPlayVideo(false);
      isSantaSpeakingRef.current = false;
    });
  };

  const handleVideoPlayback = () => {
    const video = videoRef.current;
    if (playVideo) {
      video.play();
    } else {
      video.pause();
    }
  };

  const fetchAndPlayAudio = async (textData, isGptRequired) => {
    try {
      const response = await axios.post(
        'https://jollywishlist.com/openai/api/synthesize',
        { text: textData, isGptRequired },
        { responseType: 'arraybuffer' } // Important for handling audio response
      );

      setPlayVideo(true);
      ringToneAudioRef.current.pause();
      isSantaSpeakingRef.current = true;
      // Create a Blob from the response data
      const audioBlob = new Blob([response.data], { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);

      // Create an audio element and play it
      const audio = new Audio(audioUrl);
      audioRef.current = audio;
      setAudioData(audio);
    } catch (error) {
      console.error('Error fetching audio:', error);
    }
  };

  const getProductsFromSpeech = async text => {
    try {
      const response = await axios.post('https://jollywishlist.com/openai/api/conversation', {
        prompt: `Get me product names from the below statement in JSON format only toys for age group 1 to 10 years:\n ${text}.
                Please return the response as a JSON array like this: ["product1", "product2", ...]`,
      });
      const str = response.data.data.content;
      const productNames = (str.match(/"([^"]+)"/g) || []).map(name => name.replace(/"/g, '').trim());
      const cleanedProductNames = (productNames || []).map(name => name.replace(/[.,]/, '').trim());
      if (cleanedProductNames && cleanedProductNames.length) {
        const productsResponse = await getProductList({ productList: cleanedProductNames });
        const productArr = productsResponse.data.result.reduce((productArr, prod, i) => {
          productArr.push({
            itemName: prod.itemName,
            productList: prod.productList.map(product => {
              return {
                ...product,
                itemName: prod.itemName,
                selected: false,
                position: sellerProductList.length + i,
              };
            }),
          });

          return productArr;
        }, []);

        setSellerProductList(prev => [...prev, ...productArr]);
      }

      return cleanedProductNames;
    } catch (error) {
      console.log('Error occured', error);
    }
  };

  const getMicrophoneAccess = async () => {
    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Microphone access granted!');
      return streamRef.current;
    } catch (err) {
      console.error('Microphone access denied:', err);
    }
  };

  const startMonitoring = async () => {
    const stream = await getMicrophoneAccess();
    if (stream) {
      // Set up AudioContext for processing audio
      audioContextRef.current = new AudioContext();
      processorNodeRef.current = audioContextRef.current.createScriptProcessor(2048, 1, 1); // Small buffer size
      inputNodeRef.current = audioContextRef.current.createMediaStreamSource(stream);

      // Real-time processing for detecting speech or silence
      processorNodeRef.current.onaudioprocess = detectSpeech;
      inputNodeRef.current.connect(processorNodeRef.current);
      processorNodeRef.current.connect(audioContextRef.current.destination); // Audio output
    }
  };

  const normalSilenceTimeoutRef = useRef();

  const detectSpeech = event => {
    const inputBuffer = event.inputBuffer.getChannelData(0); // Get the audio data
    let sum = 0.0;

    // Calculate the average volume of the current buffer
    for (let i = 0; i < inputBuffer.length; ++i) {
      sum += inputBuffer[i] * inputBuffer[i];
    }
    const averageVolume = Math.sqrt(sum / inputBuffer.length); // RMS calculation

    // If speaking detected, start recording if not already started
    if (averageVolume >= SILENCE_THRESHOLD) {
      if (!isSpeakingRef.current && !isSantaSpeakingRef.current) {
        isSpeakingRef.current = true;
        console.log('User started speaking...');
        startRecording(); // Start recording when speech is detected
      }
      silenceStartRef.current = null; // Reset silence timer

      if (normalSilenceTimeoutRef.current) {
        clearTimeout(normalSilenceTimeoutRef.current);
        normalSilenceTimeoutRef.current = null;
      }
    } else {
      // Silence detected
      console.log('isSpeakingRef.current', isSpeakingRef.current);
      if (isSpeakingRef.current) {
        if (!silenceStartRef.current) {
          clearTimeout(normalSilenceTimeoutRef.current);
          normalSilenceTimeoutRef.current = null;
          silenceStartRef.current = Date.now(); // Start silence timer
        } else if (Date.now() - silenceStartRef.current >= SILENCE_WAIT_TIME) {
          console.log('User stopped speaking for 1.5 seconds...');
          clearTimeout(normalSilenceTimeoutRef.current);
          normalSilenceTimeoutRef.current = null;
          stopRecording(); // Stop recording after silence
          isSpeakingRef.current = false;
          silenceStartRef.current = null; // Reset silence start time
        }
      }

      if (!normalSilenceTimeoutRef.current && !isSantaSpeakingRef.current && !isSpeakingRef.current) {
        console.log('No input');
        normalSilenceTimeoutRef.current = setTimeout(() => {
          console.log('No audio input for 5 seconds...');
          fetchAndPlayAudio('Feel free to share your wish for this eve', false); // Call the function after 5 seconds of silence
        }, 15000); // 5 seconds of normal silence
      }

      if (isSantaSpeakingRef.current || isSpeakingRef.current) {
        console.log('Someone Speaking');
        clearTimeout(normalSilenceTimeoutRef.current);
        normalSilenceTimeoutRef.current = null;
      }
    }
  };

  const startRecording = () => {
    // Initialize MediaRecorder when the user starts speaking
    mediaRecorderRef.current = new MediaRecorder(streamRef.current);
    audioChunksRef.current = []; // Clear previous audio chunks

    mediaRecorderRef.current.ondataavailable = event => {
      audioChunksRef.current.push(event.data); // Capture audio chunk during recording
    };

    mediaRecorderRef.current.onstop = async () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);

      // Send audio data to the server or process it
      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.wav');

      // Example API request to process the audio (replace with your own API)
      try {
        const response = await axios.post('https://jollywishlist.com/openai/api/recognize', formData);
        const audioTextData = response.data;

        // Display transcribed text
        if (audioTextData && audioTextData.transcript) {
          setAudioToTextData(audioTextData.transcript);
        }
      } catch (error) {
        console.log('Error occured', error);
      }
    };

    mediaRecorderRef.current.start();
    isRecordingRef.current = true;
    console.log('Recording started...');
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecordingRef.current) {
      mediaRecorderRef.current.stop();
      isRecordingRef.current = false;
      console.log('Recording stopped');
    }
  };

  const hadndleSelectProduct = selectedProduct => {
    setSellerProductList(
      sellerProductList.map((prod, i) => {
        if (i === selectedProduct.position) {
          return {
            ...prod,
            productList: prod.productList.map(product => {
              if (product.itemId === selectedProduct.itemId) {
                return {
                  ...product,
                  selected: !selectedProduct.selected,
                };
              }
              return product;
            }),
          };
        }
        return prod;
      })
    );
    countRef.current += !selectedProduct.selected ? 1 : -1;
  };

  const handleSaveWishlist = () => {
    const payload = {
      userId: userId || userData.userInfo.userid,
      childWish: [
        {
          childid: selectedProfile.id,
          wishlistitem: sellerProductList.map(item => item.itemName),
          lettertemplateurl: null,
          lettercontent: {
            name: selectedProfile.name,
            age: selectedProfile.age,
            haveBeen: null,
            did: null,
            willDo: null,
            greatful: null,
            wishlist: null,
            position: null,
          },
          selectedProducts: sellerProductList
            .map(item =>
              item.productList.reduce((acc, product) => {
                if (product.selected) {
                  acc.push(product.itemId);
                }
                return acc;
              }, [])
            )
            .flat(),
        },
      ],
    };
    saveWishList(payload);
  };

  return (
    <>
      <div className="santa-talk-container-outer">
        {isFetched && (
          <div className="santa-talk-container-outer-2">
            {sellerProductList.map((item, i) => (
              <div className="santa-talk-container-main" key={i}>
                <div className="santa-talk-product-title">
                  Product {i + 1} : <span>{item.itemName}</span>
                </div>
                <div className="santa-talk-container-products">
                  {item.productList.map((product, index) => (
                    <ProductDisplay key={index} product={product} handleSelect={hadndleSelectProduct} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className={`video-and-button-container ${isFetched ? 'fetched' : 'not-fetched'}`}>
          <video
            className={`video-container ${isFetched ? 'small' : 'large'}`}
            src={SantaTalkVideo}
            loop
            autoPlay
            muted
            ref={videoRef}
          />

          {setStep && (
            <div className={`conversion-buttons ${isFetched ? 'overlay' : ''}`}>
              <Button
                onClick={() => {
                  if (isFetched) {
                    setStep(5);
                    handleSaveWishlist();
                  } else {
                    setStep(0);
                  }
                }}
                style={{ fontSize: smallScreen ? '10px' : '15px' }}
              >
                Hang Up
              </Button>
            </div>
          )}
        </div>
      </div>
      <audio ref={ringToneAudioRef} src={ringtoneAudio} />
    </>
  );
}

import React, { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import WriteSanta from '../../../../images/LettertoSanta-Img.png';
import TalkSanta from '../../../../images/CallSanta-Img.png';
import { Button } from '../../../../components/Button';
import './style.css';

export function SelectPath({ setStep }) {
  const [microphoneAlert, setMicrophoneAlert] = useState(false);

  const getMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Microphone access granted!');
      return stream;
    } catch (err) {
      console.error('Microphone access denied:', err);
    }
  };

  const handleCallSanta = async () => {
    const stream = await getMicrophoneAccess();
    if (stream) {
      setStep(2);
    } else {
      setMicrophoneAlert(true);
    }
  };

  const handleClose = () => {
    setMicrophoneAlert(false);
  };
  return (
    <>
      <div className="select-path-container-outer">
        <div className="select-path-container">
          <div className="img-button">
            <div>
              <img className="select-path-img" src={TalkSanta} alt="Talk to Santa" />
            </div>
            <Button style={{ backgroundColor: '#AC0D1C' }} onClick={handleCallSanta}>
              Call Santa
            </Button>
          </div>
          <div className="img-button">
            <div>
              <img className="select-path-img" src={WriteSanta} alt="Write to Santa" />
            </div>
            <Button style={{ backgroundColor: '#AC0D1C' }} onClick={() => setStep(3)}>
              Letter to Santa
            </Button>
          </div>
        </div>
        {setStep && (
          <div className="select-path-buttons">
            <Button onClick={() => setStep(0)}>Previous</Button>
          </div>
        )}
      </div>
      <Snackbar
        open={microphoneAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
          Please turn on your Microphone
        </Alert>
      </Snackbar>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import './style.css';
const Snowflake = ({ id }) => {
  const [animationDelay, setAnimationDelay] = useState('0s');
  const [fontSize, setFontSize] = useState('10px');

  const generateSnowflake = () => {
    const newDelay = `${(Math.random() * 16).toFixed(2)}s`; // Random delay
    const newFontSize = `${Math.floor(Math.random() * 10) + 5}px`; // Random font size between 10px to 20px
    setAnimationDelay(newDelay);
    setFontSize(newFontSize);
  };

  useEffect(() => {
    generateSnowflake();
  }, []);

  const style = {
    animationDelay,
    fontSize,
    position: 'absolute',
    top: `${Math.random() * 100}vh`, // Random top position (within viewport height)
    left: `${Math.random() * 100}vw`, // Random left position (within viewport width)
    opacity: Math.random() * 0.8 + 0.2, // Random opacity
    animation: 'fall 18s linear infinite', // Snowflake falling animation
  };

  return (
    <p className="Snowflake" id={`item${id}`} style={style}>
      <div style={{width:'5px', height:'5px', borderRadius:'50%', backgroundColor:'white'}}></div>
    </p>
  );
};

export default Snowflake;

import React from 'react';
import FooterImg from '../../images/Footer.png';
import { Link } from 'react-router-dom';
import './style.css';
import { StarOne, StarTwo, StarThree } from '../SparkleEffect';

function Footer() {
  return (
    <div className="footer">
      <img src={FooterImg} alt="" />
      <span className='star-one'><StarOne /></span>
      <span className='star-two'><StarTwo /></span>
      <span className='star-three'><StarThree /></span>
      <div className="footer-text">
        © 2024 All rights reserved.
        <br className="break-footer" />
        <br className="break-footer" />
        <Link to="/privacy-policy" style={{ marginLeft: '4px' }}>
          Privacy Policy
        </Link>
        <span>|</span>
        <Link to="/terms-and-conditions">Terms of Service</Link>
        <span>|</span>
        <Link to="/faqs">FAQ</Link>
      </div>
    </div>
  );
}

export default Footer;

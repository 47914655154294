import { useContext, useEffect, useState } from 'react';
import { Cart } from './Cart/Cart';
import { ProductCarousel } from './ProductCarousel/ProductCarousel';
import { Button } from '../../../../../components/Button';
import { getProductList } from '../../../../../api';
import LoaderContext from '../../../../../context/LoaderContext';

export const FinaliseProducts = ({ setStep, products, setSelectedProducts, newItems, child }) => {
  const [cartCount, setCartCount] = useState(0);
  const [sellerProductList, setSellerProductList] = useState([]);
  const [selectProduct, setSelectProduct] = useState({});
  const [productToSearch, setProductToSearch] = useState([]);
  const { setIsLoading, setMessage } = useContext(LoaderContext);
  const [selectedCount, setSelectedCount] = useState(0);

  const getCartCount = () => {
    let cnt = 0;
    sellerProductList.forEach(prod => {
      prod.productList.forEach(pr => {
        if (pr.selected) cnt++;
      });
    });
    setSelectedCount(cnt);
    return cnt;
  };

  const hadndleSelectProduct = selectedProduct => {
    setSellerProductList(
      sellerProductList.map((prod, i) => {
        if (i === selectedProduct.position) {
          return {
            ...prod,
            productList: prod.productList.map(product => {
              if (product.itemId === selectedProduct.itemId) {
                return {
                  ...product,
                  selected: !selectedProduct.selected,
                };
              }
              return product;
            }),
          };
        }
        return prod;
      })
    );
    setSelectProduct(selectedProduct);
  };

  useEffect(() => {
    setCartCount(() => {
      return getCartCount();
    });
    const selectedProducts = [];
    sellerProductList.forEach(prod => {
      selectedProducts.push({
        ...prod,
        productList: prod.productList.filter(product => {
          if (product.selected) {
            return product;
          }
        }),
      });
    });
    setSelectedProducts(selectedProducts);
  }, [selectProduct]);

  useEffect(() => {
    setIsLoading(true);
    setMessage('Fetching products...');
    getProductList({ productList: [...products.map(prod => prod.name), ...newItems] }).then(res => {
      setIsLoading(false);
      setMessage();
      const productArr = res.data.result.reduce((productArr, prod, i) => {
        productArr.push({
          itemName: prod.itemName,
          productList: prod.productList.map(product => {
            return {
              ...product,
              itemName: prod.itemName,
              selected: (child.selectedProducts || []).includes(product.itemId),
              position: i,
            };
          }),
        });

        return productArr;
      }, []);
      setSellerProductList(productArr);
    });
  }, []);

  const handleSearchProduct = () => {
    setIsLoading(true);
    setMessage('Fetching products...');
    if (productToSearch) {
      getProductList({ productList: [productToSearch] }).then(res => {
        setIsLoading(false);
        setMessage();
        const productArr = res.data.result.reduce((productArr, prod, i) => {
          productArr.push({
            itemName: prod.itemName,
            productList: prod.productList.map(product => {
              return {
                ...product,
                itemName: prod.itemName,
                selected: false,
                position: sellerProductList.length + i,
              };
            }),
          });

          return productArr;
        }, []);
        setSellerProductList([...sellerProductList, ...productArr]);
      });
    }
  };

  return (
    <div className="finalise">
      <div className="search">
        <input
          type="text"
          placeholder="Search products and include them in the registry."
          onChange={e => setProductToSearch(e.target.value)}
        />
        <Button variant="secondary" onClick={() => handleSearchProduct()}>
          Search
        </Button>
      </div>
      <div className="content">
        <div>
          {sellerProductList &&
            sellerProductList.length > 0 &&
            sellerProductList.map((prod, i) => {
              return (
                <ProductCarousel
                  products={prod.productList}
                  name={prod.itemName}
                  index={i + 1}
                  hadndleSelectProduct={hadndleSelectProduct}
                  key={i}
                />
              );
            })}
        </div>
      </div>
      <Button
        variant="primary"
        style={{ margin: '3rem 0 1rem 0', float: 'left' }}
        onClick={() => setStep(prevStep => prevStep - 1)}
      >
        Previous
      </Button>

      <Button
        variant="primary"
        style={{ margin: '3rem 0 1rem 0', float: 'right' }}
        onClick={() => setStep(2)}
        disabled={selectedCount === 0}
      >
        Next
      </Button>
    </div>
  );
};

// Importing necessary React and Material-UI components
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppHeader } from '../../components/AppHeader';
import { Stepper, Step, StepLabel, IconButton } from '@mui/material';
import { SelectProfile } from './components/SelectProfile';
import { TemplateContainer } from './components/TemplateSelector/Container';
import { WriteLetter } from './components/WriteLetter';
import { KidSuccess } from './components/KidSuccess/KidSuccess';
import CloseIcon from '@mui/icons-material/Close';
import { SelectPath } from './components/SelectPath';
import SantaTalks from './components/SantaTalks';

// Importing component-specific styles
import './index.css';

// Array defining the steps of the process
const steps = ["Select a child's profile", 'Choose a template', 'Write a letter'];

/**
 * Functional component representing the Kids section.
 * @param {Object} props - Component props
 * @param {boolean} props.loggedIn - Flag indicating if the user is logged in
 * @returns {JSX.Element} - Rendered Kids component
 */
export const Kids = ({ loggedIn }) => {
  // State variables to manage the active step, selected template, selected profile, and location
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedProfile, setSelectedProfile] = useState({});
  const { pathname } = useLocation();

  // State variable and handler for displaying/hiding information box
  const [showInfoBox, setShowInfoBox] = useState(true);
  const handleInfoBoxClose = () => {
    setShowInfoBox(false);
  };

  /**
   * Helper function to get step-specific messages based on the active step and path.
   * @returns {string} - Step-specific message
   */
  const getStepMessage = () => {
    switch (activeStep) {
      case 0:
        return pathname === '/kid'
          ? '[STEP TWO] Kids - Dive into the fun! Tap on your profile to kick off creating a special letter for Santa Claus'
          : "[STEP TWO] Parents - Choose the little one who's sending an email to Santa.";
      case 3:
        return pathname === '/kid'
          ? '[STEP THREE] Kids - Pick a merry template for your message to Santa Claus.'
          : "[STEP THREE] Parents - Pick a merry template for your kids' message to Santa Claus.";
      case 4:
        return pathname === '/kid'
          ? '[STEP FOUR] CHILDREN - Immerse yourself in the magic of Christmas as you compose a special letter to Santa Claus. Let your imagination run wild as you reflect on the past year, express gratitude, and eagerly share your Christmas wishes, bringing the holiday spirit to life. When finished, click "Send to Santa" button.'
          : '[STEP FOUR] Parents - Immerse yourself in the magic of Christmas as you compose a special letter to Santa Claus. Let your imagination run wild as you reflect on the past year, express gratitude, and eagerly share your Christmas wishes, bringing the holiday spirit to life. When finished, click "Send to Santa" button.';
      case 5:
        return pathname === '/kid'
          ? '[STEP FIVE] Congratulations! Your letter has been sent successfully! Take a break for now, log out and keep an eye on your inbox to see if Santa responds tomorrow.'
          : "[STEP FIVE] Congratulations! Your child's letter has been sent successfully! Now, feel free to update and share their wishlist with friends and family.";
      default:
        return '';
    }
  };

  /**
   * Helper function to get the style for the close button based on the path.
   * @returns {Object} - Style object for close button
   */
  const getCloseButtonStyle = () => {
    return pathname === '/kid' ? { top: '80px' } : { top: '15px' };
  };

  // Rendering the Kids component
  return (
    <>
      {!loggedIn && <AppHeader showParentSignIn={true} loggedIn={loggedIn} />}
      <div className="kids">
        {showInfoBox && ![1, 2].includes(activeStep) && (
          <div
            className="message-box"
            style={{
              background: 'black',
              padding: '35px',
              fontSize: '18px',
              color: 'white',
              marginTop: '15px',
              marginLeft: '15px',
              marginRight: '15px',
              position: 'relative', // Set position relative to anchor the absolute position of the IconButton
            }}
          >
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleInfoBoxClose}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'black',
                zIndex: 1,
                // ...getCloseButtonStyle(),
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <span>
              <b>{getStepMessage()}</b>
            </span>
          </div>
        )}

        {/* Content Section */}
        <div className="kids-content">
          {/* {![1, 2].includes(activeStep) && (
          <div className="stepper-container" style={{ borderBottom: '1px solid #DDDDDD' }}>
            <Stepper activeStep={[3, 4].includes(activeStep) ? activeStep - 1 : activeStep} nonLinear={false}>
              {steps.map((label, index) => (
                <Step key={label} index={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        )} */}

          {/* Rendering different components based on the active step */}
          {activeStep === 0 && <SelectProfile setStep={setActiveStep} setSelectedProfile={setSelectedProfile} />}
          {activeStep === 1 && <SelectPath setStep={setActiveStep} />}
          {activeStep === 2 && <SantaTalks setStep={setActiveStep} selectedProfile={selectedProfile} />}
          {activeStep === 3 && <TemplateContainer setStep={setActiveStep} setSelectedTemplate={setSelectedTemplate} />}
          {activeStep === 4 && (
            <WriteLetter
              setStep={setActiveStep}
              selectedTemplate={selectedTemplate}
              selectedProfile={selectedProfile}
            />
          )}
          {activeStep > 4 && <KidSuccess setStep={setActiveStep} selectedProfile={selectedProfile} />}
        </div>
      </div>
    </>
  );
};

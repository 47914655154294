import React from 'react';
import { Modal, Box } from '@mui/material';
import StarRatings from 'react-star-ratings';
import Amazon from '../../images/amazon.png';
import Target from '../../images/target.png';
import Walmart from '../../images/walmart.png';
import Close from '../../images/close.svg';

import './style.css';
const platformImages = {
  amazon: Amazon,
  walmart: Walmart,
  target: Target,
};

function ProductDetailsModal({ openViewDetails, setOpenViewDetails, product, shareList, handleSelectProduct }) {
  return (
    <div>
      <Modal
        open={openViewDetails}
        onClose={() => setOpenViewDetails(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '80%', md: 700 },
            maxHeight: '80vh',
            overflowY: 'auto',
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: { xs: 2, sm: 4 },
          }}
        >
          <div className="view-product-details">
            <div className="description">{product.productDescription}</div>
            <div className="content-container">
              <div className="product-display">
                <img src={product.productImage} alt="Product" />
              </div>
              <div className="product-features">
                <div className="view-details-price">
                  <div className="product-name">{product.brandName}</div>
                  <div className="product-stars">
                    <span className="rating">{product.productRating}</span>
                    <StarRatings
                      rating={product.productRating && +product.productRating}
                      starRatedColor="#cc7222"
                      numberOfStars={5}
                      starDimension="18px"
                      starSpacing="4px"
                    />
                  </div>
                  <div className="product-price">{product.productPrice}</div>
                  <div className="product-platform">
                    <img src={platformImages[product.seller]} alt="Platform" />
                  </div>
                  <div>
                    {product.selected ? (
                      <div className="selected-product-view-details">
                        {/* <img src={Check} alt="Check" /> */}
                        <span onClick={() => !shareList && handleSelectProduct(product)}>Selected</span>
                      </div>
                    ) : (
                      <div className="unselected-product-view-details" onClick={() => handleSelectProduct(product)}>
                        Select
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="close-btn" onClick={() => setOpenViewDetails(false)}>
              <img src={Close} alt="Close" />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ProductDetailsModal;

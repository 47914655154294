import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AccordionComponent({ title, renderContent, onChange, isExpanded }) {
  return (
    <div>
      <Accordion onChange={onChange} expanded={isExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fontSize: '2rem' }} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="page-subheading-view">{title}</div>
        </AccordionSummary>
        <AccordionDetails>{renderContent()}</AccordionDetails>
      </Accordion>
    </div>
  );
}

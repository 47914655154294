import { useState } from 'react';
import { FaCopy } from 'react-icons/fa'; // Import the clipboard copy icon
import './ParentSuccess.css';
import SucessAnimation from '../../../../../../components/SucessAnimation';

import check from '../../../../../../images/check.png';
import KidsSanta from '../../../../../../images/parents-santa.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const ParentSuccess = ({ link }) => {
  const [copied, setCopied] = useState(false);
  return (
    <>
      <SucessAnimation />
      <div className="parent-success">
        <img src={check} alt="" />
        <p>
          Congratulations, <br />
          you've shared your registry list successfully!
        </p>
        {link && (
          <div className="link-container">
            <p>Please use the below link to order</p>
            <div className="link-section">
              <a href={link} target="_blank" rel="noopener noreferrer">
                {link}
              </a>
              <div className="copy-to-clipboard-container">
                <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
                  <FaCopy style={{ cursor: 'pointer' }} />
                </CopyToClipboard>
                {copied ? <div>Copied!</div> : null}
              </div>
            </div>
          </div>
        )}
        <img src={KidsSanta} alt="" />
      </div>
    </>
  );
};

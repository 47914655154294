import { useState, useEffect } from 'react';
import { Product } from './Product';
import { Button } from '../../../../../../components/Button';
import './ProductList.css';
import './Product.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import { getWishlist } from '../../../../../../api';
import { useUser } from '../../../../../../UserContext';
import { LetterPreview } from '../../../../../../components/LetterPreview/LetterPreview';
import CloseIcon from '@mui/icons-material/Close';
import { Input } from '@mui/material';
import Delete from '../../../../../../images/delete.svg';
import { Snackbar, Alert } from '@mui/material';

export const ProductList = ({ child, products, setProducts, newItems, setNewItems }) => {
  const [mode, setMode] = useState('view');
  const [wishList, setWishlist] = useState([]);
  const [letterContent, setLetterContent] = useState({});
  const [open, setOpen] = useState(false);
  const [openLetterAlert, setOpenLetterAlert] = useState(false);
  const { userData } = useUser();

  useEffect(() => {
    getWishlist({ userid: userData.userInfo.userid }).then(res => {
      setWishlist(res.data.wishList);
    });
  }, []);

  useEffect(() => {
    const listItem = wishList.find(list => list.childid === child.childid);
    if (wishList.length && listItem) {
      setLetterContent({
        name: listItem.lettercontent.name,
        age: listItem.lettercontent.age,
        behaviour: listItem.lettercontent.haveBeen,
        niceThings: listItem.lettercontent.did,
        thingsNextYear: listItem.lettercontent.willDo,
        gratefulFor: listItem.lettercontent.greatful,
        wishes: listItem.lettercontent.wishlist,
        position: listItem.lettercontent.position,
        templateUrl: listItem.lettertemplateurl,
      });
    }
  }, [wishList]);

  const style = {
    width: { xs: '90%', sm: '70%', md: '50%' }, // Responsive width: 90% on extra small screens, 70% on small screens, and 50% on medium and up
    maxHeight: '80vh', // Max height to prevent overflow
    overflowY: 'auto', // Enable vertical scroll for long content
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2, // Rounded corners for better appearance
    position: 'relative',
  };

  const onEdit = (id, value) => {
    setProducts(prev => {
      const selectedItem = prev.map(item => {
        if (item.id === id) {
          item.name = value;
        }
        return item;
      });
      return selectedItem;
    });
  };

  const onDelete = id => {
    setProducts(prev => {
      const selectedItem = [];
      prev.forEach(item => {
        if (item.id !== id) {
          selectedItem.push(item);
        }
      });
      return selectedItem;
    });
  };

  const handleAddItem = () => {
    setNewItems([...newItems, '']);
  };

  const handleInputChange = (index, event) => {
    const newInputFields = [...newItems];
    newInputFields[index] = event.target.value;
    setNewItems(newInputFields);
  };

  const handleNewItemDelete = index => {
    setNewItems(prev => {
      return prev.filter((it, i) => {
        if (i !== index) {
          return it;
        }
      });
    });
  };

  const handleOpen = () => {
    if (!child.lettertemplateurl) {
      setOpenLetterAlert(true);
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
    if (openLetterAlert) {
      setOpenLetterAlert(false);
    }
  };

  return (
    <>
      <div className="product-list-header">
        <div>
          <div className="page-subheading" style={{ marginBottom: '1rem' }}>
            Product List
          </div>
          <div className="page-subtext">
            Below are the items list retrieved from the letter. You may add, remove or modify below list items.
          </div>
        </div>
        <div>
          <Button variant="secondary" style={{ marginRight: '1rem' }} onClick={() => setMode('edit')}>
            Edit List
          </Button>
          <Button variant="secondary" onClick={handleOpen}>
            Preview Letter
          </Button>
        </div>
      </div>
      {products &&
        products.map((item, index) => {
          return (
            <Product
              index={index + 1}
              onDelete={onDelete}
              onEdit={onEdit}
              mode={mode}
              label={item.name}
              id={item.id}
              key={item.id}
            />
          );
        })}
      <div>
        <Box>
          {newItems.map((input, index) => (
            <div key={index} className="product">
              <div className="sl">{index + products.length + 1}.</div>
              <div className="label-input">
                <Input variant="standard" value={input} onChange={event => handleInputChange(index, event)} />
              </div>
              <div
                className="icon"
                onClick={() => {
                  handleNewItemDelete(index);
                }}
              >
                <img src={Delete} alt="" width="20px" height="20px" />
              </div>
            </div>
          ))}
        </Box>
        <Button variant="secondary" color="primary" onClick={handleAddItem}>
          Add Item
        </Button>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box className="letter-modal" sx={style}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ position: 'absolute', top: 5, right: 15 }}
            >
              <CloseIcon />
            </IconButton>
            <LetterPreview letterContent={letterContent} modal />
          </Box>
        </Modal>
        <Snackbar
          open={openLetterAlert}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
            The letter preview is not available in case of voice interaction.
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

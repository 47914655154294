import React from 'react';
import { Grid, FormControl, InputLabel, Input, MenuItem, Select } from '@mui/material';

export default function KidsForm({ newChildDetails, handleChange }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Name</InputLabel>
            <Input name="name" value={newChildDetails.name} onChange={handleChange} required />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Gender</InputLabel>
            <Select label="Gender" name="gender" value={newChildDetails.gender} onChange={handleChange} required>
              <MenuItem value="boy">Boy</MenuItem>
              <MenuItem value="girl">Girl</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Age</InputLabel>
            <Select
              label="Age"
              name="age"
              value={newChildDetails.age}
              onChange={handleChange}
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((val, i) => {
                return (
                  <MenuItem value={val} key={i}>
                    {val}
                  </MenuItem>
                );
              })}
              {/* Add more age options */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Shoe Size</InputLabel>
            <Select
              label="Shoe Size"
              name="shoeSize"
              value={newChildDetails.shoeSize}
              onChange={handleChange}
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((val, i) => {
                return (
                  <MenuItem value={val} key={i}>
                    {val}
                  </MenuItem>
                );
              })}
              {/* Add more shoe size options */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Top Size</InputLabel>
            <Select
              label="Top Size"
              name="topSize"
              value={newChildDetails.topSize}
              onChange={handleChange}
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value="12-18M (Infant)">12-18M (Infant)</MenuItem>
              <MenuItem value="18-24M (Infant)">18-24M (Infant)</MenuItem>
              <MenuItem value="2T (Toddler)">2T (Toddler)</MenuItem>
              <MenuItem value="3T (Toddler)">3T (Toddler)</MenuItem>
              <MenuItem value="4T (Toddler)">4T (Toddler)</MenuItem>
              <MenuItem value="5T (Toddler)">5T (Toddler)</MenuItem>
              <MenuItem value="4/5 (XS) Child">4/5 (XS) Child</MenuItem>
              <MenuItem value="6/7 (S) Child">6/7 (S) Child</MenuItem>
              <MenuItem value="8 (M) Child">8 (M) Child</MenuItem>
              <MenuItem value="10/12 (L) Child">10/12 (L) Child</MenuItem>

              {/* Add more top size options */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Bottom Size</InputLabel>
            <Select
              label="Bottom Size"
              name="bottomSize"
              value={newChildDetails.bottomSize}
              onChange={handleChange}
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value="12-18M (Infant)">12-18M (Infant)</MenuItem>
              <MenuItem value="18-24M (Infant)">18-24M (Infant)</MenuItem>
              <MenuItem value="2T (Toddler)">2T (Toddler)</MenuItem>
              <MenuItem value="3T (Toddler)">3T (Toddler)</MenuItem>
              <MenuItem value="4T (Toddler)">4T (Toddler)</MenuItem>
              <MenuItem value="5T (Toddler)">5T (Toddler)</MenuItem>
              <MenuItem value="4/5 (XS) Child">4/5 (XS) Child</MenuItem>
              <MenuItem value="6/7 (S) Child">6/7 (S) Child</MenuItem>
              <MenuItem value="8 (M) Child">8 (M) Child</MenuItem>
              <MenuItem value="10/12 (L) Child">10/12 (L) Child</MenuItem>

              {/* Add more bottom size options */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

// Logo.js
// import logo from '../images/Logo.jpeg';
import logo from '../images/Logo-new.svg';

/**
 * Logo Component
 * 
 * A reusable component for displaying the application logo.
 * 
 * @param {Object} style - Optional styling for the logo (e.g., height, width).
 * @returns {JSX.Element} - Returns the JSX element for the logo.
 */
export const Logo = ({ style }) => {
  return (
    <div style={{ backgroundColor: '#AC0101', borderRadius: '50%', padding: '10px' }}>
      <img
        src={logo}
        alt="logo"
        style={{
          height: '6rem', // Default height
          ...style, // Apply additional styles passed as props
        }}
      />
    </div>
  );
};

import { useState } from 'react';
import { Sidebar } from './components/Sidebar';
import { Outlet } from 'react-router-dom';
import { Drawer, IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './index.css';

export const Parent = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <Box sx={{ display: 'flex', width: '100vw', height: '100vh' }}>
        
        {/* Sidebar Drawer for small screens */}
        <IconButton
          onClick={toggleSidebar}
          sx={{ display: { xs: 'block', md: 'none' }, position: 'fixed', top: 20, left: 0,  }}
        >
          <MenuIcon />
        </IconButton>
        
        <Drawer
          variant="temporary"
          open={isSidebarOpen}
          onClose={toggleSidebar}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
          }}
        >
          <Sidebar toggleSidebar={toggleSidebar} />
        </Drawer>

        {/* Sidebar for larger screens */}
        <Box sx={{ display: { xs: 'none', md: 'block' }, width: 240 }}>
          <Sidebar />
        </Box>

        {/* Main content area */}
        <Box
          sx={{
            flexGrow: 1, // Take remaining space
            width: { xs: '100%', md: 'calc(100% - 240px)' }, // Full width on small screens, calculated on large
            overflow: 'auto', // Allows scrolling if content overflows
            // border: '10px solid black'
          }}
          className="content"
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

import React from 'react';
import Snowflake from './Snowflake';  // Path to Snowflake component

const SnowfallEffect = ({ numFlakes = 200 }) => {
  const snowflakes = new Array(numFlakes).fill('').map((_, i) => <Snowflake key={i} id={i} />);

  return (
    <div className="Snow" style={snowStyles}>
      {snowflakes}
    </div>
  );
};

// Styles to ensure snowflakes only cover the hero div
const snowStyles = {
  position: 'absolute', // Positioned absolutely within hero div
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none', // Ensure it doesn't block interactions with other elements
  zIndex: 10, // Adjust to ensure it appears in front of the background
};

export default SnowfallEffect;

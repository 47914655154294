// Importing necessary components and styles
import React, { useState } from 'react';
import { Logo } from '../Logo';
import { RightNav } from './RightNav';
import './index.css';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export const HomeHeader = ({ openRegister, setOpenRegister, openSignIn, setOpenSignIn }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery('(max-width:768px)');

  const logoStyles = isMobile ? { height: '15vw' } : {};

  // Function to handle menu open
  const handleMenuOpen = event => {
    console.log('event.currentTarget', event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  // Function to handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="header-nav">
      {/* App logo component */}
      <Logo style={logoStyles} />

      {/* Hamburger menu for small screens */}
      <IconButton
        edge="end"
        color="inherit"
        aria-label="menu"
        onClick={handleMenuOpen}
        sx={{ display: { xs: 'block', md: 'none' }, zIndex: 1}}
      >
        <MenuIcon sx={{ color: 'white' }} />
      </IconButton>

      {/* RightNav for larger screens */}
      <Box sx={{ display: { xs: 'none', md: 'block' }, zIndex: 1 }}>
        <RightNav
          openRegister={openRegister}
          setOpenRegister={setOpenRegister}
          openSignIn={openSignIn}
          setOpenSignIn={setOpenSignIn}
        />
      </Box>

      {/* Menu for small screens */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenSignIn(true);
            handleMenuClose();
          }}
        >
          Parent Sign In
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenRegister(true);
            handleMenuClose();
          }}
        >
          Register
        </MenuItem>
      </Menu>
    </div>
  );
};

import { useState, useEffect, useContext } from 'react';
import { ProfileCard } from './ProfileCard';

import Dummy1 from '../../../../images/dummy1.png';
import Dummy2 from '../../../../images/dummy2.png';
import Dummy3 from '../../../../images/dummy3.png';
import AddKidsFirst from '../../../../images/AddKidsFirst.png';
import { getChildInfo } from '../../../../api';
import { useUser } from '../../../../UserContext';
import { getWishlist } from '../../../../api';
import LoaderContext from '../../../../context/LoaderContext';

export const SelectProfile = ({ setStep, setSelectedProfile }) => {
  const [activeProfile, setActive] = useState();
  const [childDetails, setChildDetails] = useState(null);
  const [wishList, setWishList] = useState([]);
  const { userData, userId } = useUser();
  const { setIsLoading, setMessage } = useContext(LoaderContext);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setMessage('Fetching kid profiles...');
    getChildInfo({ userId: userId || userData.userInfo.userid }).then(res => {
      setIsLoading(false);
      setMessage('');
      setChildDetails(res.data.childInfo);
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    getWishlist({ userid: userId || userData.userInfo.userid }).then(res => {
      setWishList(res.data.wishList);
    });
  }, []);

  const avatars = [Dummy1, Dummy2, Dummy3];

  let i = -1;
  const profiles =
    childDetails &&
    childDetails.map(child => {
      if (child.gender === 'girl') {
        return {
          id: child.childid,
          name: child.childname,
          avatar: avatars[2],
          age: child.childage,
          gender: child.gender,
        };
      }
      i++;
      if (i === 2) {
        i = 0;
      }
      return {
        id: child.childid,
        name: child.childname,
        avatar: avatars[i],
        age: child.childage,
        gender: child.gender,
      };
    });

  if (isLoaded && (!childDetails || childDetails.length === 0)) {
    return (
      <div className="fetch-data" style={{ marginTop: 0 }}>
        {/* Image for no kids profile */}
        <img src={AddKidsFirst} alt="No Child Profiles Added" style={{ width: '40%', height: 'auto' }} />
      </div>
    );
  }

  return (
    <>
      {
        <div>
          {childDetails && childDetails.length > 0 && (
            <div className="kids-profile" style={{ marginTop: 0 }}>
              <div style={{ marginBottom: '0rem', width: '100%' }}>
                <p className="page-heading">Select a child profile</p>
                <p className="page-subtext">
                  Choose from the child's list below to create your unique letter to Santa and make your special holiday
                  wishes come true.
                  <br />
                  <span style={{ color: 'grey', fontStyle: 'italic' }}>
                    Note: A green checkmark on the profile indicates that the letter has already been submitted.
                  </span>
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  padding: '20px',
                }}
              >
                {profiles.map(profile => {
                  const sentToSanta = !!wishList.find(li => li.childid === profile.id);
                  return (
                    <ProfileCard
                      avatar={profile.avatar}
                      name={profile.name}
                      isActive={profile.id === activeProfile}
                      key={profile.id}
                      setActive={() => {
                        if (!sentToSanta) {
                          setStep(1);
                          setActive(profile.id);
                          setSelectedProfile(profile);
                        }
                      }}
                      sentToSanta={sentToSanta}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      }
    </>
  );
};

import { ContentBox } from '../../components/ContentBox';
import { HomeHeader } from '../../components/HomeHeader';
import './index.css';
import { BigText, MediumText, SmallText } from '../../components/Text';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { useContext, useEffect, useState } from 'react';
import LoaderContext from '../../context/LoaderContext';
import section1Img from '../../images/home-page-section1.png';
import section2Img from '../../images/home-page-section2.png';
import section3Img1 from '../../images/home-page-section3-img1.png';
import section3Img2 from '../../images/home-page-section3-img2.png';
import section3Img3 from '../../images/home-page-section3-img3.png';
import section4Img from '../../images/home-page-section4.png';
import section5Img from '../../images/home-page-section5.png';
import HeroBgVideo from '../../images/santa-landing-page-video.mp4';
import HeroBgMobile from '../../images/home-page.jpeg';
import Footer from '../../components/Footer';
import { StarOne, StarTwo, StarThree } from '../../components/SparkleEffect';
import SnowfallEffect from '../../components/SnowfallEffect';

const HeroBg = require('../../images/hero-bg.png');
const Content1 = require('../../images/content1.png');
const Works1 = require('../../images/Works1.png');
const Works2 = require('../../images/Works2.png');
const Works3 = require('../../images/Works3.png');
const Arrow = require('../../images/arrow-right.png');
const Content3 = require('../../images/Content3.png');
const Content4 = require('../../images/Content4.png');
// const Footer = require('../../images/Footer.png');

export const HomePage = () => {
  const [openRegister, setOpenRegister] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const currentUserId = sessionStorage.getItem('currentUserId');
  const { setIsLoading } = useContext(LoaderContext);

  useEffect(() => {
    setIsLoading(false);
  }, []);
  const handleLetsGetStarted = () => {
    if (currentUserId) {
      setOpenSignIn(true);
    } else {
      setOpenRegister(true);
    }
  };
  return (
    <div className="home">
      <div
        className="hero"
        style={{
          position: 'relative',
          overflow: 'hidden',
          backgroundImage: `url(${HeroBgMobile})`,
        }}
      >
        <SnowfallEffect numFlakes={150} />
        <HomeHeader
          openRegister={openRegister}
          setOpenRegister={setOpenRegister}
          openSignIn={openSignIn}
          setOpenSignIn={setOpenSignIn}
        />
        <div className="hero-text">
          <div>
            <p className="small">Unlock The</p>
            <p className="big">Magic of Christmas</p>
            <p className="normal">Share your wishes with Santa and make this Christmas truly magical!</p>
          </div>
        </div>
      </div>

      <div className="hero-container">
        <HomeHeader
          openRegister={openRegister}
          setOpenRegister={setOpenRegister}
          openSignIn={openSignIn}
          setOpenSignIn={setOpenSignIn}
        />
        <video className="video-background" src={HeroBgVideo} autoPlay loop muted playsInline></video>
        <div className="hero-text">
          <div className="hero-text-content">
            <p className="small">Unlock The</p>
            <p className="big">
              Magic of Christmas <StarOne />
            </p>
            <p className="normal">
              Share your wishes with Santa and make this <br /> Christmas truly magical!
              <StarThree />
            </p>
          </div>
        </div>
      </div>
      <ContentBox>
        <div className="content-1" style={{ paddingBottom: '4rem', borderBottom: '1px solid #D9D9D9' }}>
          <div className="content-1-text">
            <BigText style={{ fontSize: '2.5rem' }}>
              Christmas is a time of wonder,
              <br /> joy, and heartfelt connections
            </BigText>
            <MediumText style={{ fontSize: '1.5rem' }}>
              And what better way to celebrate the spirit of the season than by reaching out to Santa Claus himself.
            </MediumText>
            <img src={section2Img} alt="" />
            <SmallText>
              {/* Writing a <span style={{ fontWeight: 'bold' }}>letter to Santa</span> is a cherished tradition that holds
              the power to bring happiness and excitement to the holiday season. And now, you can send an email message
              directly to Santa’s Toy Shop! */}
              Share your wish with Santa is a cherished tradition that holds the power to bring happiness and excitement
              to the holiday season. And now, you can send an email message directly to Santa’s Toy Shop!
            </SmallText>
            <div>
              <Button onClick={handleLetsGetStarted}>Let`s get started</Button>
            </div>
          </div>
          <img src={section2Img} alt="" />
        </div>
      </ContentBox>
      {/* <ContentBox>
        <div className="content-2" style={{ paddingBottom: '4rem', borderBottom: '1px solid #D9D9D9' }}>
          <div className="content-2-text">
            <BigText style={{ fontSize: '2.5rem' }}>How it works</BigText>
            <MediumText style={{ fontSize: '1.5rem' }}>
              Writing a letter to Santa is easy and fun. Simply follow these steps:
            </MediumText>
            <div className="content-2-cards">
              <div className="cards-item">
                <img src={section3Img1} alt="" />
                <SmallText>Start by choosing your favorite template or crafting your own unique letter.</SmallText>
              </div>
              <img src={Arrow} alt="" />
              <div className="cards-item">
                <img src={section3Img2} alt="" />
                <SmallText>
                  Type a letter to Santa, and let him know about your wishes, dreams, and acts of kindness.
                </SmallText>
              </div>
              <img src={Arrow} alt="" />
              <div className="cards-item">
                <img src={section3Img3} alt="" />
                <SmallText>Submit your letter to Santa`s, and let the magic unfold!</SmallText>
              </div>
            </div>
            <div>
              <Button onClick={handleLetsGetStarted}>Start writing</Button>
            </div>
          </div>
        </div>
      </ContentBox> */}
      <ContentBox>
        <div className="content-3" style={{ paddingBottom: '4rem', borderBottom: '1px solid #D9D9D9' }}>
          <span className="star-1">
            <StarOne />
          </span>
          <span className="star-2">
            <StarThree />
          </span>
          <img src={section4Img} alt="" />
          <div className="content-3-text">
            <BigText style={{ fontSize: '2.5rem' }}>Santa’s promise</BigText>
            <MediumText style={{ fontSize: '1.5rem' }}>
              Santa personally engages with every child and carefully reads each email that arrives at the North Pole.
            </MediumText>
            <img src={section4Img} alt="" />
            <SmallText>
              Although he may not be able to fulfill every request, he cherishes the joy and sincerity behind every
              wish. Remember, the true magic of Christmas lies in generosity, kindness, and love.
            </SmallText>
          </div>
        </div>
      </ContentBox>
      <ContentBox>
        <div className="content-1" style={{ marginBottom: '-4rem' }}>
          <div className="content-1-text">
            <BigText style={{ fontSize: '2.5rem' }}>Start your Christmas adventure</BigText>
            <MediumText style={{ fontSize: '1.5rem' }}>
              Ready to create memories that will last forever? Start your Christmas adventure today by calling or
              emailing Santa Claus!
            </MediumText>
            <img src={section5Img} alt="" />
            <SmallText style={{ marginBottom: '20px' }}>
              Click the <b>&quot;Call Santa&quot;</b> or <b>&quot;Start Writing&quot;</b> button below to speak with
              Santa or explore our magical letter templates to bring your wishes to life.
            </SmallText>
            <SmallText>
              Spread joy, embrace the magic, and let Santa be your guide to a holiday season filled with love, laughter,
              and wonder!
            </SmallText>
            <div>
              <Button onClick={handleLetsGetStarted}>Let`s get started</Button>
            </div>
          </div>
          <img src={section5Img} alt="" />
        </div>
      </ContentBox>
      {/* <SparkleEffect /> */}
      <Footer />
    </div>
  );
};

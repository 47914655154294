import { useMemo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../../components/Button';
import { ProductCard } from '../parent/components/Wishlist/components/ProductCard/ProductCard';
import { AppHeader } from '../../components/AppHeader';
import { getOrder } from '../../api';
import { getChildInfo } from '../../api';
import { useState, useEffect } from 'react';
import LoaderContext from '../../context/LoaderContext';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const RegistryList = ({ setActiveStep, isBackButtonVisible }) => {
  // const history = useNavigate();
  const query = useQuery();
  const userid = query.get('userid');
  const childid = query.get('childid');
  const [products, setProducts] = useState();
  const [childDetails, setChildDetails] = useState({});
  const { setIsLoading } = useContext(LoaderContext);

  useEffect(() => {
    setIsLoading(true);
    if (userid) {
      getOrder({ userid: userid, childid: childid }).then(res => {
        setIsLoading(false);
        setProducts(res.data.result);
      });
      getChildInfo({ userId: userid }).then(res => {
        const child = res.data.childInfo.find(child => child.childid === childid);
        setChildDetails(child);
      });
    }
  }, [userid, childid, setIsLoading]);

  return (
    <>
      {userid && childid && (
        <div>
          <AppHeader showParentSignIn={false} />
          <div className="giftgiver registry-list">
            <div className="heading">{childDetails.childname}&apos;s registry list</div>
            <div className="subtext">
              Explore the items below and choose a present that brings joy and excitement to your loved one on this
              Christmas.
            </div>
            <div className="product-list">
              {products &&
                products.orderlist.length > 0 &&
                products.orderlist.map((prod, index) => {
                  return (
                    <>
                      {prod.productList.length > 0 && (
                        <div className="product-list-item" key={index}>
                          <div className="item-heading">
                            Product {index + 1}: <span>{prod.itemName}</span>
                          </div>
                          <div
                            className="item-products"
                            style={{
                              justifyContent: 'flex-start',
                              flexWrap: 'wrap',
                            }}
                          >
                            {prod.productList.map((product, key) => {
                              return <ProductCard key={key} product={product} variant="registry" />;
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
            {isBackButtonVisible && (
              <div className="cta">
                <Button onClick={() => setActiveStep(0)}>Back</Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
